import { type ClassValue, clsx } from "clsx";
import { ClientResponseError } from "pocketbase";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function err2str(err: Error) {
  if (err instanceof ClientResponseError) {
    const { data } = err as ClientResponseError;
    const keys = Object.keys(data.data);
    if (keys.length === 0) {
      return data.message;
    }
    return keys.map((k) => `${k}: ${data.data[k].message}`).join(". ");
  }
  return err.message;
}

export function determinePagination(
  total: number,
  current: number
): [boolean, boolean, number[], number[]] {
  const hasPrevious = current > 1;
  const hasNext = current < total;
  const prePages = [];
  for (let i = current - 2; i < current; i++) {
    if (i > 0) {
      prePages.push(i);
    }
  }
  const nextPages = [];
  for (let i = current + 1; i <= total && i <= current + 2; i++) {
    nextPages.push(i);
  }

  return [hasPrevious, hasNext, prePages, nextPages];
}

export async function fetchImage(url: string) {
  const response = await fetch(url,{
    mode:"cors",
    headers:{
    },
    referrerPolicy:"no-referrer"
  });
  // response.status = 0 by 'no-cors'
  // if(!response.ok){
  //   throw new Error(response.statusText);
  // }
  const blob = await response.blob();
  console.log("console.type",blob.type)
  if(blob.type === "image/png"){
    return new File([blob], "image.png", { type: blob.type });
  }
  return new File([blob], "image.jpg", { type: blob.type });
}


export const BlankImage = "data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E"


// tranform http://sns-webpic-qc.xhscdn.com/202408131904/e4e5b2b1827a7462df3dafbe904c98d9/010292016chzdoaptz7070qkqmo03o15ac!nd_prv_wlteh_webp_3
// to
// https://ci.xiaohongshu.com/010292016chzdoaptz7070qkqmo03o15ac!nd_prv_wlteh_webp_3
export function transformXHSImage(url?:string){
  if(!url){
    return ""
  }
  const parsed = new URL(url)

  // /202408092224/8f21abbc213d7d88fd9323907914b671/110/0/01e4c5113446d70900100000000189a1cb3cdb_0.jpg!nc_n_webp_prv_1
  const matched = parsed.pathname.match(/^\/[0-9]+\/[0-9a-z]+\/(.+)$/)
  if(matched){
    return `https://ci.xiaohongshu.com/${matched[1]}`
  }

  return url
}

export function unique<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}