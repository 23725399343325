import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
import { Check, ShieldX, TriangleAlert } from "lucide-react";

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        icon,
        title,
        description,
        action,
        ...props
      }) {
        return (
          <Toast key={id} {...props}>
            <div className="grid gap-1">
              {title && (
                <ToastTitle>
                  {icon === "ok" && (
                    <Check
                      strokeWidth={3}
                      className="inline-block mx-2 h-4 w-4 text-green-500"
                    />
                  )}
                  {icon === "warn" && (
                    <TriangleAlert
                      strokeWidth={3}
                      className="inline-block mx-2 h-4 w-4 text-yellow-500"
                    />
                  )}
                  {icon === "error" && (
                    <ShieldX
                      strokeWidth={3}
                      className="inline-block mx-2 h-4 w-4 text-red-500"
                    />
                  )}
                  {title}
                </ToastTitle>
              )}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
