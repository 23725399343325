import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useCallback, useEffect, useId, useState } from "react";
import { CalendarIcon } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { format, parse } from "date-fns";

export type Props = {
    value: string;
    onValueChange: (value: string) => void;
}
export function DatetimePicker({value,onValueChange}: Props) {
    const timeId = useId()
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Date>();
  const [time,setTime] = useState("00:00")
  const applyDate = useCallback((date:Date) => {
    onValueChange(date ? format(date, "yyyy-MM-dd HH:mm:ss") : "");
  },[onValueChange])

  useEffect(() => {
      setDate(value ? new Date(value) : undefined)
      setTime(value ? format(new Date(value), "HH:mm") : "00:00")
  },[value])
  return (
    <Popover open={open} onOpenChange={(open)=>{
        setOpen(open)
        if(!open && date){
            applyDate(date)
        }
    }}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "flex  justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          classNames={{
            row: "flex w-full mt-0",
          }}
          initialFocus
        />
        <div className="border-t p-2">
          <div className="flex items-center gap-2">
            <Label htmlFor={timeId} className="shrink-0">
              时间
            </Label>
            <Input id={timeId} type="time" placeholder="选择时间" value={time} onChange={(e)=>{
                setTime(e.target.value)
                // change time of date
                const raw = e.target.value
                setDate(parse(`${raw}:59`, "HH:mm:ss", date ?? new Date()))
            }}/>
          </div>
        </div>
        <div className="text-right pb-2 px-2">
          <Button className="" size={"sm"} onClick={() => {
            applyDate(date!)
            setOpen(false)
          }}>
            确定
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
