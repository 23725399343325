import { Button, ButtonProps } from "@/components/ui/button";
import tableContext from "@/components/data-table/tableContext";
import { LinkProps, useNavigate } from "@tanstack/react-router";
import { useContext } from "react";

export function LinkButton({
  to,
  params,
  variant = "link",
  ...props
}: Pick<LinkProps, "to" | "params"> & Omit<ButtonProps, "size" >) {
  const navigate = useNavigate();
  return (
    <Button
      size="sm"
      variant={variant}
      {...props}
      onClick={(e) => {
        navigate({ to, params });
        e.stopPropagation();
        e.preventDefault();
      }}
    />
  );
}

export function ActionButton({
  action,
  idx,
  ...props
}: Omit<ButtonProps, "size" | "variant"> & {
  action: string;
  idx: number;
}) {
  const { onAction } = useContext(tableContext);
  return (
    <Button
      size="sm"
      variant={"link"}
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (onAction) {
          onAction(action, idx);
        }
      }}
    />
  );
}
