import { createContext } from "react";

type TableContext = {
  refetch: () => void,
  setPage: (page: number) => void,
  onRowClick?: (index:number)=>void,
  onAction?: (action:string,idx:number)=>void
}

const tableContext = createContext<TableContext>({
  refetch: () => {},
  setPage: (page: number) => {
    page;
  },
});

export default tableContext;
